<template>
	<div class="form_setting">
		<el-card>
			<el-tabs v-model="activeName">
				<!-- 城市设置 -->
				<el-tab-pane label="调研表单" name="调研表单">
					<el-row class="left mb15">
						<el-col :span="24">
							<el-button type="primary" icon="el-icon-plus" @click="add">添加</el-button>
						</el-col>
					</el-row>
					<el-table :data="dataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
						<el-table-column prop="form_id" label="ID"></el-table-column>
						<el-table-column :show-overflow-tooltip="true" prop="title" label="标题" width="200"></el-table-column>
						<el-table-column prop="form_type_name" label="类型"></el-table-column>
						<el-table-column prop="input_value" label="选中值"></el-table-column>
						<el-table-column :show-overflow-tooltip="true" prop="json_str" label="表单值" width="240"></el-table-column>
						<el-table-column prop="form_order" label="排序"></el-table-column>
						<el-table-column label="是否显示">
							<template slot-scope="scope">
								<div>{{ scope.row.is_show == 1 ? "显示" : "隐藏" }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="add_time" label="添加时间" width="145px"></el-table-column>
						<el-table-column fixed="right" label="操作" width="150px">
							<template slot-scope="scope">
								<div>
									<el-button type="primary" size="mini" plain @click="edit(scope.row)">编辑</el-button>
									<el-button type="danger" size="mini" plain @click="remove(scope.row)">删除</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
					<!-- 添加城市对话框 -->
					<el-dialog :title="title" :visible.sync="formVisible" width="60%" top="5vh">
						<el-form class="left" :rules="formRules" ref="addForm" :model="addForm" label-width="105px">
							<el-row>
								<el-col :span="12">
									<el-form-item label="标题" prop="title">
										<el-input v-model="addForm.title" placeholder="请输入标题" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="使用场景" prop="form_scene">
										<el-radio v-model="addForm.form_scene" label="1">技师评价</el-radio>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="类型" prop="form_type">
										<el-radio v-model="addForm.form_type" v-for="(item,index) in type_list" :label="index">{{item}}</el-radio>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="选中值" prop="input_value">
										<el-input v-model="addForm.input_value" placeholder="请输入默认选中值" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="内容描述" prop="input_placeholder">
										<el-input v-model="addForm.input_placeholder" placeholder="请输入内容描述" />
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item prop="form_json">
										<el-input type="textarea" :rows="4" v-model="addForm.form_json"></el-input>
										<span slot="label">
							                <el-tooltip content="单选/多选请按照格式填写，如：1:男;2:女;" placement="top">
							                	<i class="el-icon-question"></i>
							                </el-tooltip>表单值
										</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="排序" prop="form_order">
										<el-input-number v-model="addForm.form_order" controls-position="right" :min="1" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="是否显示" prop="is_show">
										<el-radio v-model="addForm.is_show" label="1">是</el-radio>
										<el-radio v-model="addForm.is_show" label="2">否</el-radio>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<div class="center">
							<el-button type="default" @click="formVisible=false">取消</el-button>
							<el-button type="primary" @click="doSubmit">确认</el-button>
						</div>
					</el-dialog>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增表单项",
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				activeName: '调研表单',
				addForm: {
					title: '',
					form_scene: '1',
					form_type: '1',
					input_value: '',
					input_placeholder: '',
					form_json: '',
					form_order: '1',
					is_show: '1',
				},
				formRules: {
					title: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}],
					form_type: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}]
				},
				type_list: [],
				dataList: [],
				formVisible: false,
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.initData();
				this.getFormList();
			}
		},
		methods: {
			initData(){
				var url = 'form/initData';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.type_list = res.type_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getFormList() {
				var url = 'form/form_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.dataList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getFormList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getFormList();
			},
			add() {
				this.title = "新增表单项";
				this.addForm = {
					title: '',
					form_scene: '1',
					form_type: '1',
					input_value: '',
					input_placeholder: '',
					form_json: '',
					form_order: '1',
					is_show: '1',
				};
				this.formVisible = true
			},
			edit(scope) {
				this.title = "编辑表单项";
				this.addForm = {
					form_id: scope.form_id,
					title: scope.title,
					form_scene: scope.form_scene+'',
					form_type: scope.form_type + '',
					input_value: scope.input_value + '',
					input_placeholder: scope.input_placeholder,
					form_json: scope.form_json,
					form_order: scope.form_order + '',
					is_show: scope.is_show + '',
				};
				this.formVisible = true;
			},
			doSubmit() {
				this.$refs.addForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'form/addOrUpdate';
						let params = that.addForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.formVisible = false;
								that.$refs.addForm.resetFields();
								that.$message.success('操作成功');
								that.getFormList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 1000);
				})
			},
			remove(item) {
				this.$confirm('你确认要删除吗？,一旦删除该记录将永久删掉', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'form/deleteForm';
					let params = {
						form_id: item.form_id,
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('删除成功');
							that.getFormList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.form_setting{
		.left {
		    text-align: left;
		}
		
		.ml30 {
		    margin-left: 30px;
		}
		
		.tooltips {
		    font-size: 18px;
		    margin-left: 15px
		}
		
		.mb15 {
		    margin-bottom: 15px;
		}
		
		.center {
		    text-align: center;
		}
	}
</style>